<template>
  <footer>
    <div class="container-fluid d-flex flex-column flex-sm-row justify-content-start justify-content-sm-between align-items-center">
      <div class="logo-wrapper">
        <img src="/img/logo_white.png" class="logo"
          @click="$router.push('/').catch(() => {})"
        >
        <span class="copyright">&copy; <span class="font-montserrat"> 2024 X-GOLF Co., Ltd.</span></span>
      </div>
      <div class="my-2 my-sm-0 pt-2 pt-sm-0 small">
        {{ $t("page.customer.customer_center") }}
        <span class="font-montserrat"
        > 1544-0779
          <small class="vr"></small>
          <a href="">Contact Us</a>
        </span>
      </div>
    </div>
    <a
      href="#wrap"
      class="gotop"
      :class="{ on: last_scroll_position > 300, bottom: is_bottom }"
    >
      <i class="material-icons">expand_less</i>
    </a>    
  </footer>
</template>

<script>
export default {
  name: "Footer",
  data() {
    return {
      family: "",
      fs: false,
      last_scroll_position: 0,
      is_bottom: false,
    };
  },
  methods: {
    fs_view() {
      if (this.fs) this.fs = false;
      else this.fs = true;
    },
    fs_close() {
      this.fs = false;
    },
    onScroll() {
      const currentScrollPosition =
        window.pageYOffset || document.documentElement.scrollTop;
      if (currentScrollPosition < 0) {
        return;
      }
      this.last_scroll_position = currentScrollPosition;
      if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
        this.is_bottom = true;
      } else {
        this.is_bottom = false;
      }
    },
  },
  mounted() {
    window.addEventListener("scroll", this.onScroll);
  },
  directives: {
    "click-outside": {
      bind: function (el, binding, vnode) {
        // create event
        el.eventClickOutside = function (event) {
          // when target is not element or is not element childs
          if (!(el == event.target || el.contains(event.target))) {
            // call function
            vnode.context[binding.expression](event);
          }
        };
        // event binding
        document.body.addEventListener("click", el.eventClickOutside);
      },
      unbind: function (el) {
        document.body.removeEventListener("click", el.eventClickOutside);
      },
    },
  },
};
</script>