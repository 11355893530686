<template>
  <div>
    <span v-if="shopno" @click="getShopInfo(shopno, brandno)" class="more">
      {{ shoptitle }}
    </span>
    <b-modal
      id="modal-info-shop"
      ref="modal-info-shop"
      size="lg"
      centered
      scrollable
      footer-class="btn-bottom-wrapper"
    >
      <template #modal-header="{ close }">
        <h5 class="modal-title">
          {{ $t("page.location.location_informaition") }}
        </h5>
        <b-button variant="close" @click="close()"></b-button>
      </template>
      <template>
        <h6 class="sub-title">
          {{ $t("page.location.location_informaition") }}
        </h6>
        <div class="row g-0 table-type header-col">
          <div class="col-4 col-md-2">
            {{ $t("menu.location.location") }}
          </div>
          <div class="col-8 col-md-10 shop-photo">
            <div class="card border-0">
              <div class="row g-0">
                <div class="col-md-3 d-flex align-items-center">
                  <img
                    :src="shopbasicinfo.filepath | get_img"
                    class="img-fluid"
                    :alt="$t('title.common.basic_information')"
                  />
                </div>
                <div class="col-md-9 d-flex align-items-center">
                  <div class="card-body p-0 pt-3 pb-1 ps-md-3 py-md-2">
                    <h6
                      class="card-title mb-1 brand-name lh-base"
                      v-if="admin_login"
                    >
                      {{ shopbasicinfo.brandname }}
                    </h6>
                    <p class="shop-name lh-sm">
                      {{ shopbasicinfo && shopbasicinfo.shopname }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-4 col-md-2">
            {{ $t("title.location.address_location") }}
          </div>
          <div class="col-8 col-md-4 d-flex align-items-center">
            {{ shopbasicinfo && shopbasicinfo.shopaddress }}
          </div>

          <div class="col-4 col-md-2">
            {{ $t("title.location.memo") }}
          </div>
          <div class="col-8 col-md-4">
            <textarea class="h-100" v-model="sendData.shopmemo"></textarea>
          </div>

          <div class="col-4 col-md-2">
            {{ $t("title.common.phone") }}
          </div>
          <div class="col-8 col-md-4">
            {{ shopbasicinfo && shopbasicinfo.shopphone }}
          </div>

          <div class="col-4 col-md-2">
            {{ $t("title.location.fax") }}
          </div>
          <div class="col-8 col-md-4">
            {{ shopbasicinfo && shopbasicinfo.shopfax }}
          </div>
          <div class="col-4 col-md-2">
            {{ $t("title.location.email") }}
          </div>
          <div class="col-8 col-md-4">
            {{ shopbasicinfo && shopbasicinfo.shopemail }}
          </div>

          <div class="col-4 col-md-2">
            {{ $t("title.location.member_count") }}
          </div>
          <div class="col-8 col-md-4 align-items-center text-cyan">
            {{ shopbasicinfo && shopbasicinfo.shopmembercnt
            }}<small>{{ $t("text.common.people") }}</small>
          </div>

          <div class="col-4 col-md-2">
            {{ $t("title.location.fitting_count") }}
          </div>
          <div class="col-8 col-md-4 text-purple">
            {{ shopbasicinfo && shopbasicinfo.shopfittingcnt
            }}<small>{{ $t("text.common.cnt") }}</small>
          </div>

          <div class="col-4 col-md-2">
            {{ $t("title.location.sales_sum") }}
          </div>
          <div class="col-8 col-md-4 text-price">
            {{ shopbasicinfo && shopbasicinfo.shopsalesum | comma("-")
            }}<small v-if="shopbasicinfo.shopsalesum">{{
              $t("text.common.unit_1000")
            }}</small>
          </div>

          <div class="col-4 col-md-2 border-bottom-md-0">
            {{ $t("title.member.purchase_count") }}
          </div>
          <div class="col-8 col-md-4 border-bottom-md-0 text-orange">
            {{ shopbasicinfo && shopbasicinfo.shopsalecnt
            }}<small>{{ $t("text.common.cnt") }}</small>
          </div>

          <div class="col-4 col-md-2 border-bottom-0">
            {{ $t("title.location.as_count") }}
          </div>
          <div class="col-8 col-md-4 text-green">
            {{ shopbasicinfo && shopbasicinfo.shopascnt
            }}<small>{{ $t("text.common.cnt") }}</small>
          </div>

          <!--           <div class="col-4 col-md-2">
            <span>룸개수</span>
          </div>
          <div class="col-8 col-md-4">
            {{ shopbasicinfo && shopbasicinfo.roomcnt }}
          </div> -->
        </div>

        <h6 class="sub-title">
          {{ $t("page.location.room_information") }}
          <!-- <small class="fw-normal"
            >({{ $t("title.location.room") }}
            <b class="text-primary">{{
              shopbasicinfo && shopbasicinfo.roomcnt
            }}</b
            >{{ $t("text.common.ea") }})</small
          > -->
        </h6>

        <table class="table board table-sm" v-if="data_yn">
          <thead>
            <tr>
              <th class="w-1 border-end">
                {{ $t("title.common.club_type") }}
              </th>
              <th>
                {{ $t("title.location.detail") }}
              </th>
            </tr>
          </thead>
          <tbody v-if="roomgoodslist_.length > 0">
            <template v-for="(item1, index1) in roomgoodslist_">
              <tr
                :key="'a' + index1"
                v-if="
                  index1 == 0 ||
                  item1.roomno !== roomgoodslist_[index1 - 1].roomno
                "
              >
                <th colspan="2" class="fw-medium text-black bg-light">
                  {{ $t("title.location.room") }} :
                  {{ item1.roomname }}
                </th>
              </tr>

              <tr :key="index1 + 'r'">
                <td class="border-end goods-kind">
                  {{ club_type(item1.roomgoodstype) }}
                </td>

                <td class="p-0 goods-details">
                  <template
                    v-for="(item3, index3) in roomgoodslist.filter(
                      (v) =>
                        v.roomno == item1.roomno &&
                        v.roomgoodstype == item1.roomgoodstype
                    )"
                  >
                    <template
                      v-for="(item2, index2) in item3.roomgoodsinfodetail"
                    >
                      <table
                        v-if="index2 == 0"
                        :key="'a' + index1 + index2 + index3"
                      >
                        <tr>
                          <td class="w-1 pe-0 d-none d-sm-table-cell">
                            <img
                              :src="item3.roomgoodsimage | get_img"
                              class="img-thumbnail"
                            />
                          </td>
                          <td class="text-start">
                            <p class="small">
                              <span class="brand-name">{{
                                item3.fit_brand_nm
                              }}</span>
                            </p>
                            <p class="goods-name lh-sm">
                              {{ item3.roomgoodsname }}
                              {{ item2.clubtypename }}
                            </p>
                            <p class="mt-1 text-subscript">
                              {{ $t("title.common.loft") }}
                              {{ item2.clubloft }}&deg;<span
                                class="mx-1 text-black text-opacity-25"
                                >|</span
                              >{{ $t("title.common.strength") }}
                              {{ item3.roomgoodsbalance }}
                            </p>
                          </td>
                          <td>
                            <b-button
                              v-if="item3.roomgoodscnt > 1"
                              size="xs"
                              variant="bundle bg-primary"
                              :aria-labe="$t('button.common.more')"
                              :class="{
                                show:
                                  view_hide_shop_club ===
                                  'a' + index1 + index3 + item3.roomgoodsno,
                              }"
                              @click="
                                toggle_shop_tr(
                                  'a' + index1 + index3 + item3.roomgoodsno
                                )
                              "
                            ></b-button>
                          </td>
                        </tr>
                      </table>

                      <table v-else :key="'a' + index1 + index2 + index3">
                        <tr
                          class="bg-sky"
                          :class="{
                            show:
                              view_hide_shop_club ===
                              'a' + index1 + index3 + item3.roomgoodsno,
                          }"
                          v-if="
                            view_hide_shop_club ===
                            'a' + index1 + index3 + item3.roomgoodsno
                          "
                        >
                          <td class="w-1 pe-0 d-none d-sm-table-cell">
                            <img
                              :src="item3.roomgoodsimage | get_img"
                              class="img-thumbnail"
                            />
                          </td>
                          <td class="text-start">
                            <p class="small">
                              <span class="brand-name">{{
                                item3.fit_brand_nm
                              }}</span>
                            </p>
                            <p class="goods-name lh-sm">
                              {{ item3.roomgoodsname }}
                              {{ item2.clubtypename }}
                            </p>
                            <p class="mt-1 text-subscript">
                              {{ $t("title.common.loft") }}
                              {{ item2.clubloft }}&deg;<span
                                class="mx-1 text-black text-opacity-25"
                                >|</span
                              >{{ $t("title.common.strength") }}
                              {{ item3.roomgoodsbalance }}
                            </p>
                          </td>
                          <td></td>
                        </tr>
                      </table>
                    </template>
                  </template>
                </td>
              </tr>
            </template>
          </tbody>
          <tbody v-else>
            <tr>
              <td colspan="2">{{ $t("text.common.no_data") }}</td>
            </tr>
          </tbody>
        </table>
      </template>
      <template #modal-footer="{}">
        <b-button
          size="md"
          variant="outline-secondary"
          :aria-labe="$t('button.common.close')"
          @click="$bvModal.hide('modal-info-shop')"
        >
          {{ $t("button.common.close") }}
        </b-button>
        <b-button
          size="md"
          variant="secondary"
          :aria-labe="$t('button.common.confirm')"
          @click="setShopWrite()"
        >
          {{ $t("button.common.confirm") }}
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import ax from "@/api/shop";
import "@/filter/common";
import { myMixin } from "@/mixin/myMixin";
export default {
  name: "ShopInfo",
  props: {
    shoptitle: String,
    shopno: String,
    brandno: String,
  },
  data() {
    return {
      // playerno: "",
      shopbasicinfo: [],
      sendData: {
        shopno: "",
        brandno: "",
        shopmemo: "",
      },
      data_yn: true,
      // goods_yn: false,
      view_hide_shop_club: null,

      // roomlistcnt: 0,
      // roomlist:[],
      // roomgoodslistcnt: 0,
      roomgoodslist: [],
      roomgoodslist_: [],
      roomgoodsinfodetail: [],
      admin_login: sessionStorage.getItem("isAdmin") == "1" ? true : false,
    };
  },
  methods: {
    getShopInfo(shopno, brandno) {
      console.log(shopno, brandno);
      ax.get_shopinfo(shopno, brandno, (flag, data) => {
        console.log(flag, data);
        if (flag) {
          // this.playerno = playerno;
          this.shopbasicinfo = data.shopbasicinfo;
          this.sendData.brandno = brandno;
          this.sendData.shopno = shopno;
          this.sendData.shopmemo = data.shopbasicinfo.shopmemo;
          this.$refs["modal-info-shop"].modalOuterStyle.zIndex = 9999;
          this.$refs["modal-info-shop"].show();
          this.roomgoodslist = data.roomgoodslist;

          this.roomgoodslist_ = this.roomgoodslist.filter((v, idx) => {
            return (
              this.roomgoodslist.findIndex((v1) => {
                return (
                  v.roomno === v1.roomno && v.roomgoodstype === v1.roomgoodstype
                );
              }) === idx
            );
          });

          if (data.roomgoodslist) {
            for (let i = 0; i < data.roomgoodslist.length; i++) {
              for (
                let j = 0;
                j < data.roomgoodslist[i].roomgoodsinfodetail.length;
                j++
              ) {
                this.roomgoodsinfodetail[j] =
                  data.roomgoodslist[i].roomgoodsinfodetail[j];
                // console.log(j, this.roomgoodsinfodetail[j]);
              }
            }
          }

          // this.getShopRoomlist(shopno);
        } else {
          alert(data);
        }
      });
    },
    setShopWrite() {
      if (!this.sendData.shopmemo) {
        alert(this.$i18n.t("alert.common.enter_content"));
        return false;
      }

      ax.set_shopwrite(this.sendData, (flag, response) => {
        if (flag) {
          alert(response.message);
          $bvModal.hide("modal-info-shop");
          // this.sendData.shopmemo = "";
        } else {
          /*this.$notify({
              group: 'alert',
              type: 'info',
              title: '정보',
              text: '발송에 실패하였습니다.'
            })*/
          alert(response.message);
        }
      });
    },
    // getShopRoomlist(shopno) {
    //   ax.get_shoproomlist(shopno, (flag, data) => {
    //     console.log(flag, data);
    //     if (flag) {
    //       this.roomlistcnt = data.roomlist.length;
    //       if (data.roomlist.length <= 0) {
    //         this.data_yn = false;
    //         return false;
    //       } else {
    //         this.data_yn = true;
    //       }
    //       this.roomlist = data.roomlist;
    //       // this.roomlist.roomno = data.roomlist.roomno;
    //       // this.roomlist.roomname = data.roomlist.roomname;
    //     } else {
    //       alert(data);
    //     }
    //   });
    // },

    toggle_shop_tr(index) {
      if (this.view_hide_shop_club === index) this.view_hide_shop_club = null;
      else this.view_hide_shop_club = index;
    },
    // goods_imgview(imagename) {
    //   if (imagename !== null && imagename !== "") {
    //     //   return this.adlist[index].path + "/" + this.adlist[index].adimagepath;
    //     return process.env.VUE_APP_S3_BUCKET_URL + "/" + imagename;
    //   } else {
    //     return "/img/no-img.jpg";
    //   }
    // },
  },
  mixins: [myMixin],
};
</script>