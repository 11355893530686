import i18n from "@/i18n";

const routes = {
  path: "/stats",
  name: "Stats",
  component: () =>
    import(/* webpackChunkName: "tournament" */ "@/views/stats/Main"),
  children: [
    {
      path: "statsmain",
      name: "StatsMain",
      alias: "",
      component: () =>
        import(/* webpackChunkName: "tournament" */ "@/views/stats/StatsMain"),
      prop: true,
      meta: {
        layout: "DefaultLayout",
        wrapId: "sub",
        subtitle: i18n.t("page.stats.sales_sum_stats"),
        menu_num: 7,
        sub_menu_num: 71,
        step: [
          i18n.t("menu.stats.stats"),
          i18n.t("page.stats.sales_sum_stats"),
        ],
      },
    },
    {
      path: "statssaletotal",
      name: "StatsSaleTotal",
      component: () =>
        import(
          /* webpackChunkName: "tournament" */ "@/views/stats/StatsSaleTotal"
        ),
      prop: true,
      meta: {
        layout: "DefaultLayout",
        wrapId: "sub",
        subtitle: i18n.t("page.stats.sales_sum_stats"),
        menu_num: 7,
        sub_menu_num: 71,
        step: [
          i18n.t("menu.stats.stats"),
          i18n.t("page.stats.sales_sum_stats"),
        ],
      },
    },
    {
      path: "statssaleclub",

      name: "StatsSaleClub",
      component: () =>
        import(
          /* webpackChunkName: "tournament" */ "@/views/stats/StatsSaleClub"
        ),
      prop: true,
      meta: {
        layout: "DefaultLayout",
        wrapId: "sub",
        subtitle: i18n.t("page.stats.sales_sum_stats"),
        menu_num: 7,
        sub_menu_num: 71,
        step: [
          i18n.t("menu.stats.stats"),
          i18n.t("page.stats.sales_sum_stats"),
        ],
      },
    },
    {
      path: "statssalemodel",
      name: "StatsSaleModel",
      component: () =>
        import(
          /* webpackChunkName: "tournament" */ "@/views/stats/StatsSaleModel"
        ),
      prop: true,
      meta: {
        layout: "DefaultLayout",
        wrapId: "sub",
        subtitle: i18n.t("page.stats.sales_sum_stats"),
        menu_num: 7,
        sub_menu_num: 71,
        step: [
          i18n.t("menu.stats.stats"),
          i18n.t("page.stats.sales_sum_stats"),
        ],
      },
    },
    {
      path: "statssaleshop",
      name: "StatsSaleShop",
      component: () =>
        import(
          /* webpackChunkName: "tournament" */ "@/views/stats/StatsSaleShop"
        ),
      prop: true,
      meta: {
        layout: "DefaultLayout",
        wrapId: "sub",
        subtitle: i18n.t("page.stats.sales_sum_stats"),
        menu_num: 7,
        sub_menu_num: 71,
        step: [
          i18n.t("menu.stats.stats"),
          i18n.t("page.stats.sales_sum_stats"),
        ],
      },
    },
    {
      path: "statsfittingsales",
      name: "StatsFittingSales",
      component: () =>
        import(
          /* webpackChunkName: "tournament" */ "@/views/stats/StatsFittingSales"
        ),
      prop: true,
      meta: {
        layout: "DefaultLayout",
        wrapId: "sub",
        subtitle: i18n.t("page.stats.fitting_purchase_stats"),
        menu_num: 7,
        sub_menu_num: 72,
        step: [
          i18n.t("menu.stats.stats"),
          i18n.t("page.stats.fitting_purchase_stats"),
        ],
      },
    },
    {
      path: "statsfittinggoods",
      name: "StatsFittingGoods",
      component: () =>
        import(
          /* webpackChunkName: "tournament" */ "@/views/stats/StatsFittingGoods"
        ),
      prop: true,
      meta: {
        layout: "DefaultLayout",
        wrapId: "sub",
        subtitle: i18n.t("page.stats.fitting_purchase_stats"),
        menu_num: 7,
        sub_menu_num: 72,
        step: [
          i18n.t("menu.stats.stats"),
          i18n.t("page.stats.fitting_purchase_stats"),
        ],
      },
    },
    {
      path: "statsfittingshop",
      name: "StatsFittingShop",
      component: () =>
        import(
          /* webpackChunkName: "tournament" */ "@/views/stats/StatsFittingShop"
        ),
      prop: true,
      meta: {
        layout: "DefaultLayout",
        wrapId: "sub",
        subtitle: i18n.t("page.stats.fitting_purchase_stats"),
        menu_num: 7,
        sub_menu_num: 72,
        step: [
          i18n.t("menu.stats.stats"),
          i18n.t("page.stats.fitting_purchase_stats"),
        ],
      },
    },
    {
      path: "statsmembertotal",
      name: "StatsMemberTotal",
      component: () =>
        import(
          /* webpackChunkName: "tournament" */ "@/views/stats/StatsMemberTotal"
        ),
      prop: true,
      meta: {
        layout: "DefaultLayout",
        wrapId: "sub",
        subtitle: i18n.t("page.stats.member_stats"),
        menu_num: 7,
        sub_menu_num: 73,
        step: [
          i18n.t("menu.stats.stats"),
          i18n.t("page.stats.member_stats"),
        ],
      },
    },
    {
      path: "statsmembergender",
      name: "StatsMemberGender",
      component: () =>
        import(
          /* webpackChunkName: "tournament" */ "@/views/stats/StatsMemberGender"
        ),
      prop: true,
      meta: {
        layout: "DefaultLayout",
        wrapId: "sub",
        subtitle: i18n.t("page.stats.member_stats"),
        menu_num: 7,
        sub_menu_num: 73,
        step: [
          i18n.t("menu.stats.stats"),
          i18n.t("page.stats.member_stats"),
        ],
      },
    },
    {
      path: "statsmemberage",
      name: "StatsMemberAge",
      component: () =>
        import(
          /* webpackChunkName: "tournament" */ "@/views/stats/StatsMemberAge"
        ),
      prop: true,
      meta: {
        layout: "DefaultLayout",
        wrapId: "sub",
        subtitle: i18n.t("page.stats.member_stats"),
        menu_num: 7,
        sub_menu_num: 73,
        step: [
          i18n.t("menu.stats.stats"),
          i18n.t("page.stats.member_stats"),
        ],
      },
    },
    {
      path: "statsmemberhandicap",
      name: "StatsMemberHandicap",
      component: () =>
        import(
          /* webpackChunkName: "tournament" */ "@/views/stats/StatsMemberHandicap"
        ),
      prop: true,
      meta: {
        layout: "DefaultLayout",
        wrapId: "sub",
        subtitle: i18n.t("page.stats.member_stats"),
        menu_num: 7,
        sub_menu_num: 73,
        step: [
          i18n.t("menu.stats.stats"),
          i18n.t("page.stats.member_stats"),
        ],
      },
    },
    {
      path: "statsmembervip",
      name: "StatsMemberVip",
      component: () =>
        import(
          /* webpackChunkName: "tournament" */ "@/views/stats/StatsMemberVip"
        ),
      prop: true,
      meta: {
        layout: "DefaultLayout",
        wrapId: "sub",
        subtitle: i18n.t("page.stats.member_stats"),
        menu_num: 7,
        sub_menu_num: 73,
        step: [
          i18n.t("menu.stats.stats"),
          i18n.t("page.stats.member_stats"),
        ],
      },
    },
    {
      path: "statsmembershop",
      name: "StatsMemberShop",
      component: () =>
        import(
          /* webpackChunkName: "tournament" */ "@/views/stats/StatsMemberShop"
        ),
      prop: true,
      meta: {
        layout: "DefaultLayout",
        wrapId: "sub",
        subtitle: i18n.t("page.stats.member_stats"),
        menu_num: 7,
        sub_menu_num: 73,
        step: [
          i18n.t("menu.stats.stats"),
          i18n.t("page.stats.member_stats"),
        ],
      },
    },
  ],
};

export default routes;