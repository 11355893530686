import axios from "@/api/axios";
import store from "@/store";
import _ from "lodash";
import qs from "qs";

// 브랜드정보보기
const get_brandview = async (result) => {
  let finalData = {
    code: 200,
    message: "Ok",
    brandview: {
      email: "",
    },
  };
  const token = sessionStorage.getItem("token") || null;

  const options = {
    method: "GET",
    url: process.env.VUE_APP_API_URL + "/shop/brandview",
    headers: { Authorization: "Bearer " + token },
  };

  try {
    await axios(options).then((response) => {
      if (response.data.code === 200) {
        finalData = _.merge(finalData, response.data);
        result(true, finalData);
      } else {
        console.error(response.data.code, response.data.message);
        result(false, response.data.message);
      }
    });
  } catch (err) {
    console.error("get_brandview", err);
    result(false, err);
  }
};

const sendEmail = async (sendData, result) => {
  let finalData = {
    code: 0,
    message: "",
  };

  const options = {
    method: "POST",
    url: process.env.VUE_APP_API_URL + "/shop/shopmailsend",
    // headers: {'Content-Type': 'application/x-www-form-urlencoded'},
    data: qs.stringify(sendData),
    //params: {}
  };

  await axios(options).then((response) => {
    if (response.data.code === 200) {
      finalData = response.data;
      result(true, finalData);
    } else {
      result(false, response.data);
    }
  });
};

// 매장찾기 리스트
const get_shoplist = async (param, result) => {
  let finalData = {
    code: "",
    message: "",
    pageno: 1,
    pagesize: 10,
    findex: "S.reg_date",
    shoplist: [],
  };

  const options = {
    method: "GET",
    url: process.env.VUE_APP_API_URL + "/shop/shoplist",
    //url: 'http://localhost/test.php',
    //headers: {'Content-Type': 'application/x-www-form-urlencoded'},
    params: {
      searchtype: param.searchtype,
      searchname: param.searchname,
      findex: param.findex,
      pagesize: param.pagesize,
      pageno: param.pageno,
      brandno: param.brandno,
    },
  };
  try {
    await axios(options).then((response) => {
      if (response.data.code === 200) {
        finalData = _.merge(finalData, response.data);
        result(true, finalData);
      } else {
        console.error(response.data.code, response.data.message);
        result(false, response.data.message);
      }
    });
  } catch (err) {
    console.error("get_shoplist", err);
    result(false, err);
  }
};

// 매장정보보기
const get_shopinfo = async (shopno, brandno, result) => {
  let finalData = {
    code: "",
    message: "",
    shopbasicinfo: [],
  };

  const options = {
    method: "GET",
    url: process.env.VUE_APP_API_URL + "/shop/shopview/" + shopno,
    params: {
      brandno: brandno,
    },
  };
  try {
    await axios(options).then((response) => {
      if (response.data.code === 200) {
        finalData = _.merge(finalData, response.data);
        result(true, finalData);
      } else {
        console.error(response.data.code, response.data.message);
        result(false, response.data.message);
      }
    });
  } catch (err) {
    console.error("get_shopinfo", err);
    result(false, err);
  }
};

// 매장정보보기
const get_roominfo = async (shopno, roomno, brandno, result) => {
  let finalData = {
    code: "",
    message: "",
  };

  const options = {
    method: "GET",
    url:
      process.env.VUE_APP_API_URL + "/shop/roomview/" + shopno + "/" + roomno,
    params: {
      brandno: brandno,
    },
  };
  try {
    await axios(options).then((response) => {
      if (response.data.code === 200) {
        finalData = _.merge(finalData, response.data);
        result(true, finalData);
      } else {
        console.error(response.data.code, response.data.message);
        result(false, response.data.message);
      }
    });
  } catch (err) {
    console.error("get_roominfo", err);
    result(false, err);
  }
};

const set_shopwrite = async (sendData, result) => {
  let finalData = {
    code: 0,
    message: "",
  };
  console.log(sendData);
  // alert(sendData.memberno);
  const options = {
    method: "POST",
    url: process.env.VUE_APP_API_URL + "/shop/shopwrite",
    // headers: {'Content-Type': 'application/x-www-form-urlencoded'},
    data: qs.stringify(sendData),
    //params: {}
  };

  try {
    await axios(options).then((response) => {
      if (response.data.code === 200) {
        finalData = _.merge(finalData, response.data);
        result(true, finalData);
      } else {
        console.error(response.data.code, response.data.message);
        result(false, response.data.message);
      }
    });
  } catch (err) {
    console.error("set_shopwrite", err);
    result(false, err);
  }
};

// 매장룸찾기 리스트
const get_shoproomlist = async (shopno, result) => {
  let finalData = {
    code: "",
    message: "",
    roomlist: [],
  };

  const options = {
    method: "GET",
    url: process.env.VUE_APP_API_URL + "/shop/roomlist/" + shopno,
    // params: {
    //   shopno : shopno,
    // },
  };
  try {
    await axios(options).then((response) => {
      if (response.data.code === 200) {
        finalData = _.merge(finalData, response.data);
        result(true, finalData);
      } else {
        console.error(response.data.code, response.data.message);
        result(false, response.data.message);
      }
    });
  } catch (err) {
    console.error("get_shoproomlist", err);
    result(false, err);
  }
};

// 브랜드 리스트
const get_brandlist = async (result) => {
  let finalData = {
    code: "",
    message: "",
    searchbrandlist: [],
  };

  const options = {
    method: "GET",
    url: process.env.VUE_APP_API_URL + "/brand/brandlist",
  };

  try {
    await axios(options).then((response) => {
      if (response.data.code === 200) {
        finalData = _.merge(finalData, response.data);
        result(true, finalData);
      } else {
        console.error(response.data.code, response.data.message);
        result(false, response.data.message);
      }
    });
  } catch (err) {
    console.error("get_brandlist", err);
    result(false, err);
  }
};

// 룸 브랜드 리스트
const get_roombrandlist = async (shopno, result) => {
  let finalData = {
    code: "",
    message: "",
    roombrandlist: [],
  };

  const options = {
    method: "GET",
    url: process.env.VUE_APP_API_URL + "/shop/roombrandlist",
    params: {
      shopno: shopno,
    },
  };

  try {
    await axios(options).then((response) => {
      if (response.data.code === 200) {
        finalData = _.merge(finalData, response.data);
        result(true, finalData);
      } else {
        console.error(response.data.code, response.data.message);
        result(false, response.data.message);
      }
    });
  } catch (err) {
    console.error("get_roombrandlist", err);
    result(false, err);
  }
};

// 매장찾기 리스트
const get_searchshoplist = async (searchshopname, result) => {
  let finalData = {
    code: "",
    message: "",
    searchshoplist: [],
  };

  const options = {
    method: "GET",
    url: process.env.VUE_APP_API_URL + "/shop/searchshoplist",
    //url: 'http://localhost/test.php',
    //headers: {'Content-Type': 'application/x-www-form-urlencoded'},
    params: {
      searchshopname: searchshopname,
    },
  };

  try {
    await axios(options).then((response) => {
      if (response.data.code === 200) {
        finalData = _.merge(finalData, response.data);
        result(true, finalData);
      } else {
        console.error(response.data.code, response.data.message);
        result(false, response.data);
      }
    });
  } catch (err) {
    console.error("get_searchshoplist", err);
    result(false, err);
  }
};

const set_shopregister = async (searchshopbrandno, searchshopno, result) => {
  console.log(searchshopbrandno, searchshopno);
  let finalData = {
    code: 0,
    message: "",
  };

  // alert(searchshopbrandno);
  // alert(searchshopno);
  const options = {
    method: "POST",
    url: process.env.VUE_APP_API_URL + "/shop/shopregister",
    // headers: {'Content-Type': 'application/x-www-form-urlencoded'},
    // data: qs.stringify(searchshopbrandno,searchshopno),
    params: {
      searchshopbrandno: searchshopbrandno,
      searchshopno: searchshopno,
    },
  };

  try {
    await axios(options).then((response) => {
      if (response.data.code === 200) {
        finalData = _.merge(finalData, response.data);
        result(true, finalData);
      } else {
        console.error(response.data.code, response.data.message);
        result(false, response.data);
      }
    });
  } catch (err) {
    console.error("set_shopregister", err);
    result(false, err);
  }
};

const set_roomgoodsedit = async (
  shoproomgoodsno,
  roomgoodsbalancecode,
  result
) => {
  console.log(shoproomgoodsno, roomgoodsbalancecode);
  let finalData = {
    code: 0,
    message: "",
  };

  // alert(searchshopbrandno);
  // alert(searchshopno);
  const options = {
    method: "POST",
    url: process.env.VUE_APP_API_URL + "/shop/shopgoodsedit",
    // headers: {'Content-Type': 'application/x-www-form-urlencoded'},
    // data: qs.stringify(searchshopbrandno,searchshopno),
    params: {
      shoproomgoodsno: shoproomgoodsno,
      roomgoodsbalancecode: roomgoodsbalancecode,
    },
  };

  try {
    await axios(options).then((response) => {
      if (response.data.code === 200) {
        finalData = _.merge(finalData, response.data);
        result(true, finalData);
      } else {
        console.error(response.data.code, response.data.message);
        result(false, response.data.message);
      }
    });
  } catch (err) {
    console.error("set_shopregister", err);
    result(false, err);
  }
};

const set_roomgoodsdrop = async (shoproomgoodsno, result) => {
  console.log(shoproomgoodsno);
  let finalData = {
    code: 0,
    message: "",
  };

  // alert(searchshopbrandno);
  // alert(searchshopno);
  const options = {
    method: "POST",
    url: process.env.VUE_APP_API_URL + "/shop/shopgoodsdrop/" + shoproomgoodsno,
    // headers: {'Content-Type': 'application/x-www-form-urlencoded'},
    // data: qs.stringify(searchshopbrandno,searchshopno),
  };

  try {
    await axios(options).then((response) => {
      if (response.data.code === 200) {
        finalData = _.merge(finalData, response.data);
        result(true, finalData);
      } else {
        console.error(response.data.code, response.data.message);
        result(false, response.data.message);
      }
    });
  } catch (err) {
    console.error("set_shopregister", err);
    result(false, err);
  }
};

// 매장제품정보보기
const get_brandgoodsinfo = async (brandno, result) => {
  let finalData = {
    code: "",
    message: "",
  };

  const options = {
    method: "GET",
    url: process.env.VUE_APP_API_URL + "/shop/brandgoodsview",
    params: {
      brandno: brandno,
    },
  };
  try {
    await axios(options).then((response) => {
      if (response.data.code === 200) {
        finalData = _.merge(finalData, response.data);
        result(true, finalData);
      } else {
        console.error(response.data.code, response.data.message);
        result(false, response.data.message);
      }
    });
  } catch (err) {
    console.error("get_brandgoodsinfo", err);
    result(false, err);
  }
};

const set_shoproomregister = async (form, result) => {
  // for (let key in form) {
  //   console.log(key, form[key]);
  // }
  console.log(form);

  const data = new FormData();

  for (let key in form) {
    if (Array.isArray(form[key])) {
      form[key].forEach((v) => data.append(key + "[]", v));
    } else if (
      typeof form[key] === "object" &&
      !Array.isArray(form[key]) &&
      form[key] !== null
    ) {
      data.append(key, JSON.stringify(form[key]));
    } else {
      data.append(key, form[key]);
    }
  }

  const options = {
    method: "POST",
    url: "/shop/shoproomregister",
    // headers: { "Content-Type": "multipart/form-data" },
    data: data,
  };
  try {
    await axios(options).then((response) => {
      if (response.data.code === 200) {
        result(true, response.data);
      } else {
        console.error(response.data.code, response.data.message);
        result(false, response.data);
      }
    });
  } catch (err) {
    result(false, err, 400);
  }
};

const post_shopdel = async (sendData, result) => {
  // for (let key in form) {
  //   console.log(key, form[key]);
  // }

  const options = {
    method: "POST",
    url: "/postact/shopdel",
    // headers: { "Content-Type": "multipart/form-data" },
    data: qs.stringify(sendData),
  };
  try {
    await axios(options).then((response) => {
      if (response.data.code === 200) {
        result(true, response.data);
      } else {
        console.error(response.data.code, response.data.message);
        result(false, response.data);
      }
    });
  } catch (err) {
    result(false, err, 400);
  }
};

export default {
  get_brandview,
  sendEmail,
  get_shoplist,
  get_shopinfo,
  get_roominfo,
  set_shopwrite,
  get_shoproomlist,
  get_brandlist,
  get_roombrandlist,
  get_searchshoplist,
  set_shopregister,
  set_roomgoodsedit,
  set_roomgoodsdrop,
  get_brandgoodsinfo,
  set_shoproomregister,
  post_shopdel,
};
