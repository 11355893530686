<template>
  <nav id="ddmenu">
    <header>
      <div class="container-fluid d-none d-lg-flex">
        <div class="header-item">
          <router-link @click.native="forceRerender()" to="/">
            <img src="/img/logo_black.png" alt="X-GOLF" class="logo" />
          </router-link>
          <div class="hstack">
            <div class="vr"></div>
            X-FITTING SYSTEM
          </div>
        </div>
        <div class="header-item header-utility">
          <b-dropdown
            id="dropdown-right"
            right
            size="lg"
            variant="link"
            toggle-class="text-decoration-none"
            no-caret
            class="dropdown-notice"
          >
            <template #button-content>
              <span
                class="material-icons-outlined"
                @click="notifications = true"
                >notifications <small class="icon-new-alert"></small
                ><!-- d-none -->
              </span>
            </template>
            <b-dropdown-text v-if="notifications">
              <div class="card">
                <div class="card-header">
                  <h6>{{ $t("title.common.notifications") }}</h6>
                  <span class="badge bg-green">{{ items.length }} New</span>
                </div>
                <div class="card-body scrollbar-auto-hide">
                  <div class="scrollbar-content-wrapper">
                    <div
                      class="list-group list-group-flush"
                      v-for="item in items"
                      :key="'nk' + item.noticeno"
                      @click="notifications = false"
                    >
                      <router-link
                        class="list-group-item list-group-item-action"
                        @click.native="forceRerender()"
                        :to="get_badge_link(item)"
                      >
                        <p class="text-truncate">
                          <span class="badge" :class="get_badge_btn(item)">{{
                            get_badge_text(item)
                          }}</span>

                          ({{ item.clubtype_code_name }}){{
                            item.goodsname
                          }}
                          [{{ item.nickname }}]
                        </p>

                        <span class="text-date">
                          <i class="material-icons-outlined">schedule</i>
                          {{ item.timezone_regdate | dateformat }}
                        </span>
                      </router-link>
                    </div>
                    <div class="py-2 text-center" v-if="!items.length">
                      {{ $t("text.common.no_fitting_request") }}
                    </div>
                  </div>
                </div>
                <div
                  class="card-footer text-end"
                  @click="notifications = false"
                >
                  <router-link
                    @click.native="forceRerender()"
                    :to="{ name: 'FittingList', query: { goodstype: 0 } }"
                  >
                    {{ $t("page.customer.total_list") }}
                  </router-link>
                </div>
              </div>
            </b-dropdown-text>
          </b-dropdown>

          <b-dropdown
            id="dropdown-right"
            right
            size="lg"
            variant="link"
            toggle-class="text-decoration-none"
            no-caret
            class="dropdown-account ms-2"
          >
            <template #button-content>
              <span class="material-icons-outlined">person_outline</span>
            </template>
            <b-dropdown-text
              >welcome

              {{ nickname }}</b-dropdown-text
            >
            <b-dropdown-item>
              <router-link
                @click.native="forceRerender()"
                :to="{ name: 'BrandPwdCheck' }"
              >
                <i class="material-icons-outlined">manage_accounts</i>
                {{ $t("menu.membership.my_account") }}
              </router-link>
            </b-dropdown-item>
            <b-dropdown-item
              @click="
                $router.push({
                  name: 'Logout',
                })
              "
              ><i class="material-icons-outlined">logout</i>
              {{ $t("button.login.logout") }}
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </div>

      <div class="container-fluid position-relative d-lg-none">
        <div class="header-item header-nav">
          <span class="material-icons-outlined navi_back" @click="goBack"
            >arrow_back_ios</span
          >
          <!-- <span
            class="material-icons-outlined navi_home"
            @click="$router.push('/').catch(() => {})"
            >home</span
          > -->
          <span
            class="navi_home"
            @click="$router.push('/').catch(() => {})"
          ></span>
        </div>
        <div class="header-item header-title">
          {{ step1 }}
          <!-- <select
            class="w-auto"
            aria-label="브랜드선택"
            v-model="brandno"
            v-if="admin_login && menu_num != '9' && menu_num != '10'"
          >
            <option value="0">브랜드선택</option>
            <option
              :value="br.brandno"
              v-for="(br, index) in brandlist"
              :key="index"
            >
              {{ br.brandname }}
            </option>
          </select> -->
        </div>
        <div
          class="header-item me-1"
          :class="[{ 'menu-icon-active': mMenu }]"
          @click="mmenu_on_off()"
        >
          <span class="hamburger-icon" aria-label="navigation menu">
            <span></span>
            <span></span>
            <span></span>
          </span>
        </div>
      </div>
    </header>
    <div class="container-fluid nav-menu">
      <ul :class="{ open: mMenu }">
        <li class="mobile-nav-header">
          <div>
            <strong>{{ nickname }}</strong>
            <div class="ms-auto">
              <router-link
                @click.native="forceRerender()"
                :to="{ name: 'FittingList', query: { goodstype: 0 } }"
              >
                <i class="position-relative material-icons-outlined me-2"
                  >notifications<small class="icon-new-alert"></small>
                </i>
              </router-link>
              <router-link
                to="/login"
                @click.native="forceRerender()"
                v-if="!login"
              >
                <i class="material-icons-outlined">manage_accounts</i>
              </router-link>
              <router-link
                @click.native="forceRerender()"
                to="/account/brandpwdcheck"
                v-else
              >
                <i class="material-icons-outlined">manage_accounts</i>
              </router-link>
              <router-link
                @click.native="forceRerender()"
                to="/login"
                v-if="!login"
              >
                <i class="material-icons-outlined">login </i>
              </router-link>
              <router-link
                @click.native="forceRerender()"
                class="ms-2 me-1"
                to="/logout"
                v-else
              >
                <i class="material-icons-outlined">logout</i>
              </router-link>
              <span class="mo_close" @click="mmenu_on_off" aria-label="close">
                <i class="material-icons-outlined">close</i>
              </span>
            </div>
          </div>
          <select
            :aria-label="$t('title.common.choose_brand')"
            v-model="brandno"
            v-if="admin_login && menu_num != '9' && menu_num != '10'"
          >
            <option value="0">{{ $t("title.common.choose_brand") }}</option>
            <option
              :value="br.brandno"
              v-for="(br, index) in brandlist"
              :key="index"
            >
              {{ br.brandname }}
            </option>
          </select>
        </li>
        <!-- //mobile-nav-header -->
        <li
          :class="{ over: menu === 10 }"
          @mouseleave="menu_off()"
          @click="
            menu_on_m(10);
            mMenu = false;
          "
          v-if="admin_login"
        >
          <router-link
            @click.native="forceRerender()"
            to="/brand/adminbrandlist"
            :class="{ active: sub_menu_num === 101 }"
          >
            <span
              class="nav-item"
              :class="{ active: menu_num === 10 }"
              @mouseover="menu_on(10)"
            >
              {{ $t("menu.brand.brand") }}
            </span>
          </router-link>
        </li>
        <li
          :class="{ over: menu === 1 }"
          @mouseleave="menu_off()"
          @click="menu_on_m(1)"
        >
          <span
            class="nav-item"
            :class="{ active: menu_num === 1 }"
            @mouseover="menu_on(1)"
          >
            {{ $t("menu.goods.goods") }}
          </span>
          <div class="dropdown" v-if="menu === 1">
            <div class="dd-inner">
              <ul class="column">
                <li>
                  <router-link
                    @click.native="forceRerender()"
                    to="/goods/goodslist/0"
                    :class="{ active: sub_menu_num === 11 }"
                  >
                    <span>{{ $t("menu.goods.goods_type") }}</span>
                  </router-link>
                </li>
                <!-- <li>
                  <router-link
                  @click.native="forceRerender()"
                    to="/goods/shaftlist"
                    :class="{ active: sub_menu_num === 12 }"
                  >
                    <span>샤프트리스트</span>
                  </router-link>
                </li>
                <li>
                  <router-link
                  @click.native="forceRerender()"
                    to="/goods/griplist"
                    :class="{ active: sub_menu_num === 13 }"
                  >
                    <span>그립리스트</span>
                  </router-link>
                </li> -->
                <li>
                  <router-link
                    @click.native="forceRerender()"
                    to="/goods/goodswrite"
                    :class="{ active: sub_menu_num === 12 }"
                  >
                    <span>{{ $t("menu.goods.club_write") }}</span>
                  </router-link>
                </li>
                <li>
                  <router-link
                    @click.native="forceRerender()"
                    to="/goods/ShaftWrite"
                    :class="{ active: sub_menu_num === 13 }"
                  >
                    <span>{{ $t("menu.goods.shaft_write") }}</span>
                  </router-link>
                </li>
                <li>
                  <router-link
                    @click.native="forceRerender()"
                    to="/goods/gripwrite"
                    :class="{ active: sub_menu_num === 14 }"
                  >
                    <span>{{ $t("menu.goods.grip_write") }}</span>
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
        </li>
        <li
          :class="{ over: menu === 2 }"
          @mouseleave="menu_off()"
          @click="menu_on_m(2)"
        >
          <span
            class="nav-item"
            :class="{ active: menu_num === 2 }"
            @mouseover="menu_on(2)"
          >
            {{ $t("menu.ad.ad") }}
          </span>
          <div class="dropdown" v-if="menu === 2">
            <div class="dd-inner">
              <ul class="column">
                <li>
                  <router-link
                    @click.native="forceRerender()"
                    :to="{ path: '/ad/adlist/1/0' }"
                    :class="{ active: sub_menu_num === 211 }"
                  >
                    <span>{{ $t("menu.ad.ad_movie") }}</span>
                  </router-link>
                </li>
                <li>
                  <router-link
                    @click.native="forceRerender()"
                    :to="{ path: '/ad/adlist/2/0' }"
                    :class="{ active: sub_menu_num === 221 }"
                  >
                    <span>{{ $t("menu.ad.ad_title") }}</span>
                  </router-link>
                </li>
                <li>
                  <router-link
                    @click.native="forceRerender()"
                    :to="{ path: '/ad/adlist/3/0' }"
                    :class="{ active: sub_menu_num === 231 }"
                  >
                    <span>{{ $t("menu.ad.ad_goods") }}</span>
                  </router-link>
                </li>
                <li>
                  <router-link
                    @click.native="forceRerender()"
                    to="/ad/adwrite"
                    :class="{ active: sub_menu_num === 24 }"
                  >
                    <span>{{ $t("menu.ad.ad_write") }}</span>
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
        </li>
        <li
          :class="{ over: menu === 3 }"
          @mouseleave="menu_off()"
          @click="
            menu_on_m(3);
            mMenu = false;
          "
        >
          <router-link
            @click.native="forceRerender()"
            to="/shop/ShopList"
            :class="{ active: sub_menu_num === 31 }"
          >
            <span
              class="nav-item"
              :class="{ active: menu_num === 3 }"
              @mouseover="menu_on(3)"
            >
              {{ $t("menu.location.location") }}
            </span>
          </router-link>
        </li>
        <li
          :class="{ over: menu === 4 }"
          @mouseleave="menu_off()"
          @click="
            menu_on_m(4);
            mMenu = false;
          "
        >
          <router-link
            @click.native="forceRerender()"
            to="/member/memberlist"
            :class="{ active: sub_menu_num === 41 }"
          >
            <span
              class="nav-item"
              :class="{ active: menu_num === 4 }"
              @mouseover="menu_on(4)"
            >
              {{ $t("menu.member.member") }}
            </span>
          </router-link>
        </li>
        <li
          :class="{ over: menu === 5 }"
          @mouseleave="menu_off()"
          @click="
            menu_on_m(5);
            mMenu = false;
          "
        >
          <router-link
            @click.native="forceRerender()"
            :to="{
              name: 'FittingList',
              query: { goodstype: 0 },
            }"
            :class="{ active: sub_menu_num === 51 }"
          >
            <span
              class="nav-item"
              :class="{ active: menu_num === 5 }"
              @mouseover="menu_on(5)"
            >
              {{ $t("menu.fitting.fitting") }}
            </span>
          </router-link>
        </li>
        <li
          :class="{ over: menu === 6 }"
          @mouseleave="menu_off()"
          @click="
            menu_on_m(6);
            mMenu = false;
          "
        >
          <router-link
            @click.native="forceRerender()"
            :to="{ name: 'FittingSaleList', query: { goodstype: '0' } }"
            :class="{ active: sub_menu_num === 61 }"
          >
            <span
              class="nav-item"
              :class="{ active: menu_num === 6 }"
              @mouseover="menu_on(6)"
            >
              {{ $t("menu.sales.sales") }}
            </span>
          </router-link>
        </li>
        <li
          :class="{ over: menu === 7 }"
          @mouseleave="menu_off()"
          @click="menu_on_m(7)"
        >
          <span
            class="nav-item"
            :class="{ active: menu_num === 7 }"
            @mouseover="menu_on(7)"
          >
            {{ $t("menu.stats.stats") }}
          </span>
          <div class="dropdown" v-if="menu === 7">
            <div class="dd-inner">
              <ul class="column">
                <li>
                  <router-link
                    @click.native="forceRerender()"
                    to="/stats/statsmain"
                    :class="{ active: sub_menu_num === 71 }"
                  >
                    <span>{{ $t("menu.stats.sales") }}</span>
                  </router-link>
                </li>
                <li>
                  <router-link
                    @click.native="forceRerender()"
                    to="/stats/statsfittingsales"
                    :class="{ active: sub_menu_num === 72 }"
                  >
                    <span>{{ $t("menu.stats.fitting_purchase") }}</span>
                  </router-link>
                </li>
                <li>
                  <router-link
                    @click.native="forceRerender()"
                    to="/stats/statsmembertotal"
                    :class="{ active: sub_menu_num === 73 }"
                  >
                    <span>{{ $t("menu.stats.member") }}</span>
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
        </li>
        <li
          :class="{ over: menu === 8 }"
          @mouseleave="menu_off()"
          @click="menu_on_m(8)"
        >
          <router-link
            @click.native="forceRerender()"
            :to="{ name: 'NoticeList', query: { category: '0' } }"
            :class="{ active: menu_num === 8 }"
          >
            <span
              class="nav-item"
              :class="{ active: menu_num === 8 }"
              @mouseover="menu_on(8)"
            >
              {{ $t("menu.customer.customer") }}
            </span>
          </router-link>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
// import ax from "@/api/header";

export default {
  name: "Header",
  data() {
    return {
      items: this.$store.state.notification || [],
      menu: 0,
      mMenu: false,
      is_mobile: false,
      notifications: false,
      login: sessionStorage.getItem("token") ? true : false,
      admin_login: sessionStorage.getItem("isAdmin") == "1" ? true : false,
      last_scroll_position: 0,
      icon_visible: true,
      brandno:
        (this.$store.state.admin && this.$store.state.admin.brandno) || "0",
      brandlist:
        (this.$store.state.admin && this.$store.state.admin.brandList) || [],
      // admin_login: sessionStorage.getItem("isAdmin") == "1" ? true : false,
      nickname: sessionStorage.getItem("nickname") || "",
    };
  },
  methods: {
    forceRerender: function () {
      this.mMenu = false;
      this.$store.commit("setSearchData", {});
      this.$store.commit("forceRerender");
    },
    goBack: function () {
      this.$router.go(-1);
    },
    menu_on: function (eq) {
      if (this.is_mobile) return;
      this.menu = eq;
    },
    menu_on_m: function (eq) {
      if (!this.is_mobile) return;
      if (this.menu === eq) this.menu = 0;
      else this.menu = eq;
    },
    menu_off: function () {
      if (this.is_mobile) return;
      this.menu = 0;
    },
    // get_brandname: function () {
    //   if (this.brandlist.length && this.brandno != 0) {
    //     return this.brandlist.find((v) => v.brandno == this.brandno).brandname;
    //   }

    //   return this.$i18n.t("title.common.admin");
    // },
    get_catagory: function () {
      return (
        this.$route.query.category ||
        (this.$store.state.searchData[this.$route.name] &&
          this.$store.state.searchData[this.$route.name].category) ||
        ""
      );
    },
    mmenu_on_off: function () {
      if (this.mMenu === false) {
        this.menu = this.menu_num;
        this.mMenu = true;
      } else this.mMenu = false;
    },
    check_mobile: function () {
      let filter = "win16|win32|win64|mac|macintel";
      if (navigator.platform) {
        if (filter.indexOf(navigator.platform.toLowerCase()) < 0) {
          this.is_mobile = true;
        } else {
          this.is_mobile = false;
        }
      }
    },
    onScroll: function () {
      const currentScrollPosition =
        window.pageYOffset || document.documentElement.scrollTop;
      if (currentScrollPosition < 0) {
        return;
      }
      this.last_scroll_position = currentScrollPosition;
    },
    check_store: function () {
      if (sessionStorage.getItem("token")) {
        this.$store.dispatch("check_login");
      }
    },
    widthCheck() {
      if (window.innerWidth < 1000) {
        this.icon_visible = false;
      } else {
        this.icon_visible = true;
      }
    },
    handleResize(event) {
      if (window.innerWidth < 1000) {
        this.icon_visible = false;
      } else {
        this.icon_visible = true;
      }
    },
    resizeEvent() {
      window.addEventListener("resize", this.handleResize);
    },
    get_badge_link: function (item) {
      return {
        name: "FittingSwingList",
        params: {
          fittingno: item.fittingno,
        },
        query: { goodstype: 0 },
      };
    },
    get_badge_btn: function (item) {
      if (item.fittingstatus == "0") return "bg-orange";
      if (item.fittingstatus == "1") return "bg-orange";
      if (item.fit_ans_fk != "0" && item.fittingstatus == "1")
        return "bg-orange";
      if (item.fit_ans_fk != "0" && item.fittingstatus == "2")
        return "bg-orange";
    },
    get_badge_text: function (item) {
      if (item.fittingstatus == "0")
        return this.$i18n.t("title.main.fitting_ready");
      if (item.fittingstatus == "1")
        return this.$i18n.t("title.main.fitting_done");
      if (item.fit_ans_fk != "0" && item.fittingstatus == "1")
        return this.$i18n.t("title.main.shipping_now");
      if (item.fit_ans_fk != "0" && item.fittingstatus == "2")
        return this.$i18n.t("title.main.shipping_done");
    },
  },
  mounted() {
    if (this.$store.state.status === "success") {
      this.login = true;
    }
    window.addEventListener("scroll", this.onScroll);
    this.resizeEvent();
  },
  beforeDestroy() {
    this.resizeEvent();
  },
  created() {
    this.check_mobile();
    this.check_store();
    this.widthCheck();
    if (this.brandno != "0") {
      this.items = this.items.filter((v) => {
        return v.fit_brand_fk == this.brandno;
      });
    }
  },
  watch: {
    "$store.state.status": function () {
      this.login = this.$store.state.status === "success" ? true : false;
      this.nickname = sessionStorage.getItem("nickname") || "";
    },
    "$route.name": function () {
      this.mMenu = false;
    },
    "$route.meta.menu_num": function () {
      this.memu = this.$route.meta.menu_num;
    },
    brandno(newVal) {
      this.$store.commit("setBrandno", newVal);
      window.location.reload(true);
    },
  },
  computed: {
    /*     logo: function() {
      if (
        this.$route.name === "Login" ||
        this.$route.name === "Find_Id_Pwd" ||
        this.$route.name === "Terms" ||
        this.$route.name === "Privacy" ||
        this.$route.name === "ConfirmPassword" ||
        this.last_scroll_position > 0
      )
        return "/img/logo_black.png";
      else return "/img/logo_white.png";
    }, */
    step1: function () {
      return this.$route.meta.step ? this.$route.meta.step[0] : "";
    },
    menu_num: function () {
      return this.$route.meta.menu_num || "";
    },
    sub_menu_num: function () {
      return this.$route.meta.sub_menu_num || "";
    },
    // sub_menu_num_last_str: function () {
    //   const lastStr = String(this.$route.meta.sub_menu_num || "");
    //   return lastStr.substr(1);
    // },
  },
};
</script>
