import i18n from "@/i18n";

const routes = {
  path: "/customer",
  name: "Customer",
  component: () =>
    import(/* webpackChunkName: "tournament" */ "@/views/customer/Main"),
  children: [
    {
      path: "noticelist",
      alias: "",
      name: "NoticeList",
      category: 0,
      component: () =>
        import(
          /* webpackChunkName: "tournament" */ "@/views/customer/NoticeList"
        ),
      prop: true,
      meta: {
        layout: "DefaultLayout",
        wrapId: "sub",
        subtitle: i18n.t("menu.customer.customer"),
        menu_num: 8,
        sub_menu_num: 81,
        step: [i18n.t("menu.customer.customer")],
        category: 0,
      },
    },
    {
      path: "noticeview/:noticeno",
      name: "NoticeView",
      component: () =>
        import(
          /* webpackChunkName: "tournament" */ "@/views/customer/NoticeView"
        ),
      prop: true,
      meta: {
        layout: "DefaultLayout",
        wrapId: "sub",
        subtitle: i18n.t("page.customer.detail_view"),
        menu_num: 8,
        sub_menu_num: 0,
        step: [
          i18n.t("menu.customer.customer"),
          i18n.t("page.customer.detail_view"),
        ],
      },
    },
    {
      path: "noticewrite/:noticeno?",
      name: "NoticeWrite",
      component: () =>
        import(
          /* webpackChunkName: "tournament" */ "@/views/customer/NoticeWrite"
        ),
      prop: true,
      meta: {
        layout: "DefaultLayout",
        wrapId: "sub",
        subtitle: i18n.t("page.customer.write"),
        menu_num: 8,
        sub_menu_num: 0,
        step: [
          i18n.t("menu.customer.customer"),
          /* i18n.t("page.customer.write"), */
        ],
      },
    },
    {
      path: "noticereplywrite/:noticeno",
      name: "NoticeReplyWrite",
      component: () =>
        import(
          /* webpackChunkName: "tournament" */ "@/views/customer/NoticeReplyWrite"
        ),
      prop: true,
      meta: {
        layout: "DefaultLayout",
        wrapId: "sub",
        subtitle: i18n.t("page.customer.answer_write"),
        menu_num: 8,
        sub_menu_num: 0,
        step: [
          i18n.t("menu.customer.customer"),
          /* i18n.t("page.customer.answer_write"), */
        ],
      },
    },
  ],
};

export default routes;
