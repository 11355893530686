import i18n from "@/i18n";

const routes = {
  path: "/goods",
  name: "Goods",
  component: () =>
    import(/* webpackChunkName: "tournament" */ "@/views/goods/Main"),
  children: [
    {
      path: "goodslist/:goodstype?",
      alias: "",
      name: "GoodsList",
      component: () =>
        import(/* webpackChunkName: "tournament" */ "@/views/goods/GoodsList"),
      prop: true,
      meta: {
        layout: "DefaultLayout",
        wrapId: "sub",
        subtitle: i18n.t("menu.goods.goods_type"),
        menu_num: 1,
        sub_menu_num: 11,
        step: [
          i18n.t("menu.goods.goods"),
          i18n.t("menu.goods.goods_type"),
        ],         
      },
    },
    {
      path: "goodswrite/:goodsno?",
      name: "GoodsWrite",
      component: () =>
        import(/* webpackChunkName: "tournament" */ "@/views/goods/GoodsWrite"),
      prop: true,
      meta: {
        layout: "DefaultLayout",
        wrapId: "sub",
        subtitle: i18n.t("menu.goods.club_write"),
        menu_num: 1,
        sub_menu_num: 12,
        step: [
          i18n.t("menu.goods.goods"),
          i18n.t("menu.goods.club_write"),
        ], 
      },
    },
    {
      path: "shaftlist",
      alias: "",
      name: "ShaftList",
      component: () =>
        import(/* webpackChunkName: "tournament" */ "@/views/goods/ShaftList"),
      prop: true,
      meta: {
        layout: "DefaultLayout",
        wrapId: "sub",
        subtitle: i18n.t("menu.goods.goods_type"),
        menu_num: 1,
        sub_menu_num: 11,
        step: [
          i18n.t("menu.goods.goods"),
          i18n.t("menu.goods.goods_type"),
        ],
      },
    },
    {
      path: "shaftwrite/:shaftno?",
      name: "ShaftWrite",
      component: () =>
        import(/* webpackChunkName: "tournament" */ "@/views/goods/ShaftWrite"),
      prop: true,
      meta: {
        layout: "DefaultLayout",
        wrapId: "sub",
        subtitle: i18n.t("menu.goods.shaft_write"),
        menu_num: 1,
        sub_menu_num: 13,
        step: [
          i18n.t("menu.goods.goods"),
          i18n.t("menu.goods.shaft_write"),
        ], 
      },
    },
    {
      path: "griplist",
      alias: "",
      name: "GripList",
      component: () =>
        import(/* webpackChunkName: "tournament" */ "@/views/goods/GripList"),
      prop: true,
      meta: {
        layout: "DefaultLayout",
        wrapId: "sub",
        subtitle: i18n.t("menu.goods.goods_type"),
        menu_num: 1,
        sub_menu_num: 11,
        step: [
          i18n.t("menu.goods.goods"),
          i18n.t("menu.goods.goods_type"),
        ],
      },
    },
    {
      path: "gripwrite/:gripno?",
      name: "GripWrite",
      component: () =>
        import(/* webpackChunkName: "tournament" */ "@/views/goods/GripWrite"),
      prop: true,
      meta: {
        layout: "DefaultLayout",
        wrapId: "sub",
        subtitle: i18n.t("menu.goods.grip_write"),
        menu_num: 1,
        sub_menu_num: 14,
        step: [
          i18n.t("menu.goods.goods"),
          i18n.t("menu.goods.grip_write"),
        ], 
      },
    },
  ],
};

export default routes;