<template>
  <div>
    <span v-if="memberno" @click="getMemberInfo(memberno)" class="more">
      {{ nickname }}
    </span>
    <b-modal
      id="modal-info-member"
      ref="modal-info-member"
      size="lg"
      centered
      scrollable
      footer-class="btn-bottom-wrapper"
    >
      <template #modal-header="{ close }">
        <h5 class="modal-title">{{ $t("page.common.member_informaition") }}</h5>
        <b-button variant="close" @click="close()"></b-button>
      </template>
      <template>
        <h6 class="sub-title">{{ $t("page.common.basic_information") }}</h6>
        <div class="row g-0 table-type header-col">
          <div class="col-md-2">
            <span>{{ $t("title.member.nickname") }}</span>
          </div>
          <div class="col-md-4">
            {{ playerbasicinfo && playerbasicinfo.membername }}
          </div>
          <div class="col-md-2">
            <span>{{ $t("title.member.gender") }}</span>
          </div>
          <div class="col-md-4">
            {{ playerbasicinfo && playerbasicinfo.membergender | gender }}
          </div>

          <div class="col-md-2">
            <span>{{ $t("title.member.registration_date") }}</span>
          </div>
          <div class="col-md-4 text-date">
            {{
              playerbasicinfo && playerbasicinfo.timezone_regdate | dateformat
            }}
          </div>
          <div class="col-md-2">
            <span>{{ $t("title.member.birth_date") }}</span>
          </div>
          <div class="col-md-4 text-date">
            {{ playerbasicinfo && playerbasicinfo.birthdate }}
          </div>

          <div class="col-md-2">
            <span>{{ $t("title.member.favorite_location") }}</span>
          </div>
          <div class="col-md-4 lh-sm shop-name">
            {{ playerbasicinfo && playerbasicinfo.shopname }}
          </div>
          <div class="col-md-2">
            <span>{{ $t("title.member.email") }}</span>
          </div>
          <div class="col-md-4 lh-sm">
            {{ playerbasicinfo && playerbasicinfo.email }}
          </div>

          <div class="col-md-2">
            <span>{{ $t("title.member.fitting_count") }}</span>
          </div>
          <div class="col-md-4">
            <h5 class="fw-semibold text-purple">
              {{ playerbasicinfo && playerbasicinfo.fittingcnt | comma("-") }}<small
              >{{ $t("text.common.each") }}</small>
            </h5>
          </div>
          <div class="col-md-2">
            <span>{{ $t("title.member.purchase_count") }}</span>
          </div>
          <div class="col-md-4">
            <h5 class="fw-semibold text-orange">
              {{ playerbasicinfo && playerbasicinfo.salecnt | comma("-") }}<small
              >{{ $t("text.common.each") }}</small>
            </h5>
          </div>

          <div class="col-md-2">{{ $t("title.member.memo") }}</div>
          <div class="col-md-10">
            <textarea class="" v-model="sendData.membermemo"></textarea>
          </div>
        </div>

        <h6 class="sub-title">{{ $t("page.common.profile_information") }}</h6>
        <div class="row g-0 table-type header-col">
          <div class="col-md-3">
            <span>{{ $t("title.member.height") }}</span>
          </div>
          <div class="col-md-3">
            {{ playerbasicinfo && playerbasicinfo.height }} cm
          </div>
          <div class="col-md-3">
            <span>{{ $t("title.member.wrist_height") }}</span>
          </div>
          <div class="col-md-3">
            {{ playerbasicinfo && playerbasicinfo.handheight }} cm
          </div>

          <div class="col-md-3">
            <span>{{ $t("title.member.weight") }}</span>
          </div>
          <div class="col-md-3">
            {{ playerbasicinfo && playerbasicinfo.weight }} kg
          </div>
          <div class="col-md-3">
            <span>{{ $t("title.member.handicap") }}</span>
          </div>
          <div class="col-md-3">
            {{ playerbasicinfo.handicap | get_handicap }}
          </div>

          <div class="col-md-3">
            <span>{{ $t("title.member.avg_driver_distance") }}</span>
          </div>
          <div class="col-md-3">
            {{ playerbasicinfo.driverdistance | get_driverdistance }} m
          </div>
          <div class="col-md-3">
            <span>{{ $t("title.member.important_of_club") }}</span>
          </div>
          <div class="col-md-3">
            {{ playerbasicinfo.interestclub | get_interestclub }}
          </div>

          <div class="col-md-3">
            <span>{{ $t("title.member.favorite_shaft_strength") }}</span>
          </div>
          <div class="col-md-3">
            {{ playerbasicinfo.interestshaft | get_balance }}
          </div>
          <div class="col-md-3">
            <span>{{ $t("title.member.gloves_size") }}</span>
          </div>
          <div class="col-md-3">
            {{ playerbasicinfo && playerbasicinfo.glovesize }} cm
          </div>

          <div class="col-md-3">
            <span>{{ $t("title.member.middle_finger_size") }}</span>
          </div>
          <div class="col-md-3">
            {{ playerbasicinfo && playerbasicinfo.fingersize }} cm
          </div>
          <div class="col-md-3">
            <span>{{ $t("title.member.shirts_size") }}</span>
          </div>
          <div class="col-md-3">
            {{ playerbasicinfo && playerbasicinfo.shirtssize }} cm
          </div>

          <div class="col-md-3">
            <span>{{ $t("title.member.pants_size") }}</span>
          </div>
          <div class="col-md-3">
            {{ playerbasicinfo && playerbasicinfo.pantssize }} Inch
          </div>
          <div class="col-md-3">
            <span>{{ $t("title.member.shoes_size") }}</span>
          </div>
          <div class="col-md-3">
            {{ playerbasicinfo && playerbasicinfo.shoessize }} mm
          </div>

          <div class="col-md-3">
            <span>{{ $t("title.member.current_use_club") }}</span>
          </div>
          <div class="col-md-9 py-0">
            <div class="row p-2 ps-0 border-bottom">
              <div class="col-md-3 goods-kind">
                {{ $t("title.common.driver") }}
              </div>
              <div class="col-md-9 small">
                <span class="brand-name">{{
                  playerbasicinfo && playerbasicinfo.drivername
                }}</span>
                <span class="text-date">
                  / {{ playerbasicinfo && playerbasicinfo.driverdate }}
                  {{ $t("text.common.year") }}</span
                >
              </div>
            </div>

            <div class="row p-2 ps-0 border-bottom">
              <div class="col-md-3 goods-kind">
                {{ $t("title.common.wood") }}
              </div>
              <div class="col-md-9 small">
                <span class="brand-name">{{
                  playerbasicinfo && playerbasicinfo.woodname
                }}</span>
                <span class="text-date">
                  / {{ playerbasicinfo && playerbasicinfo.wooddate }}
                  {{ $t("text.common.year") }}</span
                >
              </div>
            </div>

            <div class="row p-2 ps-0 border-bottom">
              <div class="col-md-3 goods-kind">
                {{ $t("title.common.iron") }}
              </div>
              <div class="col-md-9 small">
                <span class="brand-name">{{
                  playerbasicinfo && playerbasicinfo.ironname
                }}</span>
                <span class="text-date">
                  / {{ playerbasicinfo && playerbasicinfo.irondate }}
                  {{ $t("text.common.year") }}</span
                >
              </div>
            </div>

            <div class="row p-2 ps-0">
              <div class="col-md-3 goods-kind">
                {{ $t("title.common.putter") }}
              </div>
              <div class="col-md-9 small">
                <span class="brand-name">{{
                  playerbasicinfo && playerbasicinfo.puttername
                }}</span>
                <span class="text-date">
                  / {{ playerbasicinfo && playerbasicinfo.putterdate }}
                  {{ $t("text.common.year") }}</span
                >
              </div>
            </div>
          </div>
        </div>
      </template>
      <template #modal-footer="{}">
        <b-button
          size="md"
          variant="outline-secondary"
          :aria-labe="$t('button.common.close')"
          @click="$bvModal.hide('modal-info-member')"
        >
          {{ $t("button.common.close") }}
        </b-button>
        <b-button
          size="md"
          variant="secondary"
          :aria-labe="$t('button.common.confirm')"
          @click="setMemberWrite()"
        >
          {{ $t("button.common.confirm") }}
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import ax from "@/api/member";
import "@/filter/common";
export default {
  name: "MemberInfo",
  props: {
    nickname: String,
    memberno: String,
  },
  data() {
    return {
      // playerno: "",
      playerbasicinfo: [],
      sendData: {
        memberno: "",
        membermemo: "",
      },
    };
  },
  methods: {
    getMemberInfo(memberno) {
      ax.get_playerinfo(memberno, (flag, data) => {
        console.log(flag, data);
        if (flag) {
          // this.playerno = playerno;
          this.playerbasicinfo = data.playerbasicinfo;
          this.sendData.memberno = memberno;
          this.sendData.membermemo = data.playerbasicinfo.membermemo;
          this.$refs["modal-info-member"].modalOuterStyle.zIndex = 9999;
          this.$refs["modal-info-member"].show();
        } else {
          alert(data);
        }
      });
    },
    setMemberWrite() {
      if (!this.sendData.membermemo) {
        alert(this.$i18n.t("alert.common.enter_content"));
        return false;
      }

      ax.set_memberwrite(this.sendData, (flag, response) => {
        if (flag) {
          alert(response.message);
          this.$bvModal.hide("modal-info-member");
          // this.sendData.membermemo = "";
        } else {
          /*this.$notify({
              group: 'alert',
              type: 'info',
              title: '정보',
              text: '발송에 실패하였습니다.'
            })*/
          alert(response.message);
        }
      });
    },
  },
};
</script>
