<template>
  <div>
    <div v-if="roomno" @click="getRoomInfo(shopno, roomno)">
      {{ roomname }}
    </div>
    <b-modal
      id="modal-info-room"
      ref="modal-info-room"
      size="lg"
      centered
      scrollable
      footer-class="btn-bottom-wrapper"
    >
      <template #modal-header="{ close }">
        <h5 class="modal-title">{{ $t("page.location.room_information") }}</h5>
        <b-button variant="close" @click="close()"></b-button>
      </template>
      <template>
        <h6 class="sub-title">
          <p class="mb-2 brand-name">
            <small>{{ brandname }}</small>
          </p>
          <div class="d-flex align-items-center">
            <div class="shop-name fw-bold">{{ shoptitle }}</div>
            <div class="vr mx-2"></div>
            <div>{{ $t("title.location.room") }} : {{ roomname }}</div>
          </div>
        </h6>

        <table class="table board table-sm" v-if="data_yn">
          <thead>
            <tr>
              <th class="w-1 border-end">
                {{ $t("title.common.club_type") }}
              </th>
              <th>
                {{ $t("title.location.detail") }}
              </th>
            </tr>
          </thead>
          <tbody v-if="roomgoodslist_.length > 0">
            <template v-for="(item1, index1) in roomgoodslist_">
              <tr :key="index1 + 'r'">
                <td class="border-end goods-kind">
                  {{ club_type(item1.roomgoodstype) }}
                </td>

                <td class="p-0 goods-details">
                  <template
                    v-for="(item3, index3) in roomgoodslist.filter(
                      (v) =>
                        v.roomno == item1.roomno &&
                        v.roomgoodstype == item1.roomgoodstype
                    )"
                  >
                    <template
                      v-for="(item2, index2) in item3.roomgoodsinfodetail"
                    >
                      <table
                        v-if="index2 == 0"
                        :key="'a' + index1 + index2 + index3"
                      >
                        <tr v-if="index2 == 0" :key="'a' + index2">
                          <td class="w-1 pe-0 d-none d-sm-table-cell">
                            <img
                              :src="item3.roomgoodsimage | get_img"
                              class="img-thumbnail"
                            />
                          </td>
                          <td class="text-start">
                            <p class="goods-name lh-sm">
                              {{ item3.roomgoodsname }}
                              {{ item2.clubtypename }}
                            </p>
                            <p class="text-subscript">
                              {{ $t("title.common.loft") }}
                              {{ item2.clubloft }}&deg;<span
                                class="mx-1 text-black text-opacity-25"
                                >|</span
                              >{{ $t("title.common.strength") }}
                              <select
                                :placeholder="$t('title.common.flex')"
                                v-model="
                                  roomgoodsbalancecode[
                                    'a' + item3.shoproomgoodsno
                                  ]
                                "
                                v-if="admin_login"
                                class="text-type"
                              >
                                <!-- <option value="">
                                {{ $t("title.common.flex") }}
                              </option> -->
                                <option
                                  :value="item3.fit_balance1"
                                  v-if="item3.fit_balance1 > 0"
                                >
                                  {{ item3.fit_balance1 | get_balance }}
                                </option>

                                <option
                                  :value="item3.fit_balance2"
                                  v-if="item3.fit_balance2 > 0"
                                >
                                  {{ item3.fit_balance2 | get_balance }}
                                </option>

                                <option
                                  :value="item3.fit_balance3"
                                  v-if="item3.fit_balance3 > 0"
                                >
                                  {{ item3.fit_balance3 | get_balance }}
                                </option>

                                <option
                                  :value="item3.fit_balance4"
                                  v-if="item3.fit_balance4 > 0"
                                >
                                  {{ item3.fit_balance4 | get_balance }}
                                </option>
                                <option
                                  :value="item3.fit_balance5"
                                  v-if="item3.fit_balance5 > 0"
                                >
                                  {{ item3.fit_balance5 | get_balance }}
                                </option>
                              </select>
                              <template v-else>{{
                                item2.roomgoodsbalance
                              }}</template>
                            </p>
                            <p class="text-subscript text-date fs-12">
                              {{ item2.roomgoodsdate }}
                            </p>
                            <p v-if="admin_login" class="mt-2">
                              <b-button
                                size="xs"
                                variant="secondary"
                                class="fs-12"
                                :aria-labe="$t('button.common.edit')"
                                @click="setRoomGoodsEdit(item3.shoproomgoodsno)"
                              >
                                {{ $t("button.common.edit") }}
                              </b-button>
                              <b-button
                                size="xs"
                                variant="outline-secondary"
                                class="fs-12"
                                :aria-labe="$t('button.common.delete')"
                                @click="setRoomGoodsDrop(item3.shoproomgoodsno)"
                              >
                                {{ $t("button.common.delete") }}
                              </b-button>
                            </p>
                          </td>
                          <td>
                            <b-button
                              v-if="item3.roomgoodscnt > 1"
                              size="xs"
                              variant="bundle bg-primary"
                              :aria-labe="$t('button.common.more')"
                              :class="{
                                show:
                                  view_hide_room_club ===
                                  'a' + index1 + index3 + item3.roomgoodsno,
                              }"
                              @click="
                                toggle_club_tr(
                                  'a' + index1 + index3 + item3.roomgoodsno
                                )
                              "
                            ></b-button>
                          </td>
                        </tr>
                      </table>
                      <table v-else :key="'a' + index1 + index2 + index3">
                        <tr
                          class="bg-sky"
                          :class="{
                            show:
                              view_hide_room_club ===
                              'a' + index1 + index3 + item3.roomgoodsno,
                          }"
                          v-if="
                            view_hide_room_club ===
                            'a' + index1 + index3 + item3.roomgoodsno
                          "
                        >
                          <td class="w-1 pe-0 d-none d-sm-table-cell">
                            <img
                              :src="item3.roomgoodsimage | get_img"
                              class="img-thumbnail"
                            />
                          </td>
                          <td class="text-start">
                            <p class="goods-name lh-sm">
                              {{ item3.roomgoodsname }}
                              {{ item2.clubtypename }}
                            </p>
                            <p class="text-subscript">
                              {{ $t("title.common.loft") }}
                              {{ item2.clubloft }}&deg;
                            </p>
                            <p class="text-subscript text-date fs-12">
                              {{ item2.roomgoodsdate }}
                            </p>
                          </td>
                          <td></td>
                        </tr>
                      </table>
                    </template>
                  </template>
                </td>
              </tr>
            </template>
          </tbody>
          <tbody v-else>
            <tr>
              <td colspan="2">{{ $t("text.common.no_data") }}</td>
            </tr>
          </tbody>
        </table>
      </template>
      <template #modal-footer="{}">
        <b-button
          size="md"
          variant="outline-secondary"
          :aria-labe="$t('button.common.close')"
          @click="
            $bvModal.hide('modal-info-room');
            $emit('getshoplist');
          "
        >
          {{ $t("button.common.close") }}
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import ax from "@/api/shop";
import "@/filter/common";
import { myMixin } from "@/mixin/myMixin";
export default {
  name: "RoomInfo",
  props: {
    shopno: String,
    shoptitle: String,
    roomno: String,
    roomname: String,
    brandno: String,
    brandname: String,
    getshoplist: Function,
  },
  data() {
    return {
      // playerno: "",
      data_yn: true,
      view_hide_room_club: null,
      view_hide_room: null,
      // roomlistcnt: 0,
      // roomlist:[],
      // roomgoodslistcnt: 0,
      roomgoodslist: [],
      roomgoodslist_: [],
      roomgoodsinfodetail: [],
      shoproomgoodsno: [],
      roomgoodsbalancecode: {},
      admin_login: sessionStorage.getItem("isAdmin") == "1" ? true : false,
    };
  },

  methods: {
    getRoomInfo(shopno, roomno, brandno) {
      ax.get_roominfo(shopno, roomno, brandno, (flag, data) => {
        console.log(flag, data);
        if (flag) {
          // this.playerno = playerno;
          // this.$refs["modal-info-room"].modalOuterStyle.zIndex = 9999;
          this.$refs["modal-info-room"].show();
          this.roomgoodslist = data.roomgoodslist;

          this.roomgoodslist_ = this.roomgoodslist.filter((v, idx) => {
            return (
              this.roomgoodslist.findIndex((v1) => {
                return (
                  v.roomno === v1.roomno && v.roomgoodstype === v1.roomgoodstype
                );
              }) === idx
            );
          });

          for (let i = 0; i < data.roomgoodslist.length; i++) {
            this.shoproomgoodsno[i] = data.roomgoodslist[i].shoproomgoodsno;
            for (
              let j = 0;
              j < data.roomgoodslist[i].roomgoodsinfodetail.length;
              j++
            ) {
              this.roomgoodsinfodetail[j] =
                data.roomgoodslist[i].roomgoodsinfodetail[j];
              this.roomgoodsbalancecode["a" + this.shoproomgoodsno[i]] =
                data.roomgoodslist[i].roomgoodsinfodetail[
                  j
                ].roomgoodsbalancecode;
            }
          }
          console.log("this.roomgoodsbalancecode", this.roomgoodsbalancecode);
          // this.getShopRoomlist(shopno);
        } else {
          alert(data);
        }
      });
    },
    // getRoomInfonew(shopno, brandno) {
    //   ax.get_roominfonew(shopno, brandno, (flag, data) => {
    //     console.log(flag, data);
    //     if (flag) {
    //       // this.playerno = playerno;
    //       this.$refs["modal-info-room"].modalOuterStyle.zIndex = 9999;
    //       this.$refs["modal-info-room"].show();
    //       this.roomgoodslist = data.roomgoodslist;

    //       for (let i = 0; i < data.roomgoodslist.length; i++) {
    //         for (
    //           let j = 0;
    //           j < data.roomgoodslist[i].roomgoodsinfodetail.length;
    //           j++
    //         ) {
    //           this.roomgoodsinfodetail[j] =
    //             data.roomgoodslist[i].roomgoodsinfodetail[j];
    //           // console.log(j, this.roomgoodsinfodetail[j]);
    //         }
    //       }

    //       // this.getShopRoomlist(shopno);
    //     } else {
    //       alert(data);
    //     }
    //   });
    // },
    toggle_room_tr(index) {
      if (this.view_hide_room === index) this.view_hide_room = null;
      else this.view_hide_room = index;
    },
    toggle_club_tr(index) {
      if (this.view_hide_room_club === index) this.view_hide_room_club = null;
      else this.view_hide_room_club = index;
    },
    // goods_imgview(imagename) {
    //   if (imagename !== null && imagename !== "") {
    //     //   return this.adlist[index].path + "/" + this.adlist[index].adimagepath;
    //     return process.env.VUE_APP_S3_BUCKET_URL + "/" + imagename;
    //   } else {
    //     return "/img/no-img.jpg";
    //   }
    // },
    setRoomGoodsEdit(num1) {
      console.log(num1);

      if (!this.roomgoodsbalancecode["a" + num1]) {
        alert(this.$i18n.t("alert.common.enter_strength"));
        return false;
      }

      ax.set_roomgoodsedit(
        num1,
        this.roomgoodsbalancecode["a" + num1],
        (flag, response) => {
          console.log(flag, response);
          if (flag) {
            alert(response.message);
            this.getRoomInfo(this.shopno, this.roomno);
            // this.$bvModal.hide("modal-infor-room");
            // this.sendData.shopmemo = "";
          } else {
            /*this.$notify({
              group: 'alert',
              type: 'info',
              title: '정보',
              text: '발송에 실패하였습니다.'
            })*/
            alert(response.message);
          }
        }
      );
    },
    setRoomGoodsDrop(num) {
      console.log(num);

      if (confirm(this.$i18n.t("alert.common.want_to_delete"))) {
        ax.set_roomgoodsdrop(num, (flag, response) => {
          console.log(flag, response);
          if (flag) {
            alert(response.message);
            this.getRoomInfo(this.shopno, this.roomno);
            // this.$bvModal.hide("modal-infor-room");
            // this.sendData.shopmemo = "";
          } else {
            /*this.$notify({
                group: 'alert',
                type: 'info',
                title: '정보',
                text: '발송에 실패하였습니다.'
              })*/
            alert(response.message);
          }
        });
      }
    },
  },
  mixins: [myMixin],
};
</script>
