"use strict";

import axios from "axios";

const _axios = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  withCredentials: false,
  timeout: 10000,
});

const requestHandler = (request) => {
  /*  if (isHandlerEnabled(request)) {
      console.log("Request Interceptor", request);
    }*/
  return request;
};

const errorHandler = async (error) => {
  console.log("Error Interceptor", error);
  if (error.response) {
    if (error.response.status === 401) {
      // if ( await refreshTokenInternal()) {
      //   return  Promise.resolve(_axios(error.config))
      // } else {
      //   window.location.replace('/')
      //   return Promise.reject(new Error('로그인이 만료 되었습니다.'))
      // }
    }
  }
  console.log("error reject");
  return Promise.reject({ ...error });
};

const successHandler = (response) => {
  /*  if (isHandlerEnabled(response.config)) {
      console.log("Response Interceptor", response)
    }*/
  return response;
};

_axios.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    let accessToken =
      sessionStorage.getItem("token") || localStorage.getItem("token");
    config.headers.Authorization = "Bearer " + accessToken;
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

_axios.interceptors.response.use(
  (response) => {
    if (response.data.code === 401) {
      store.dispatch("logout");
      window.location.href = "/login";
    }
    return response;
  },
  async (error) => {
    const {
      config,
      response: { status, data },
    } = error;
    // const originalRequest = config
    if (status === 401 && data === "Unauthorized1") {
      return new Promise((resolve) => {
        // window.location.href = '/login'
        return Promise.reject(new Error("Your login has expired.1"));

        // auth.refreshToken((flag, accessToken) => {
        //   if (flag) {
        //     originalRequest.headers.Authorization = accessToken
        //     resolve(axios(originalRequest))
        //   } else {
        //     store.dispatch('logout')
        //     window.location.href = '/'
        //     return Promise.reject(new Error('로그인이 만료 되었습니다.'))
        //   }
        // })
      });
    } else {
      // await store.dispatch('logout')
      // window.location.href = '/login'
      return Promise.reject(new Error("Your login has expired.2"));
    }
  }
);

export default _axios;
